var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isMobile)?_c('div',{staticClass:"flex flex-col items-center justify-center",class:{ 'w-[12rem]': _vm.size == 'sm', 'w-[16rem]': _vm.size != 'sm' }},[(_vm.showBranding)?_c('div',{staticClass:"flex gap-1 justify-between w-full"},[_c('div',{staticClass:"font-extrabold text-sm leading-4"},[_vm._v(" "+_vm._s(_vm.$t('passwordlessLogin.loginWith'))),_c('br'),_vm._v(" UC One ")]),_c('div',[_c('fw-icon-uc-one',{staticClass:"w-14 h-9"})],1)]):_vm._e(),_c('div',{staticClass:"block-uc-id-qr-code flex flex-col justify-center items-center",class:{ 'h-[15rem]': _vm.size == 'sm', 'h-[19rem]': _vm.size != 'sm' }},[_c('div',{staticClass:"flex",on:{"click":_vm.reactivatePasswordless}},[(!_vm.isLoading && _vm.qrCode)?_c('div',[_c('div',{staticClass:"flex justify-center p-1 relative"},[_c('qrcode-vue',{staticClass:"p-2 flex items-center justify-center bg-white rounded",class:{ 'opacity-50': _vm.disabled },attrs:{"value":_vm.qrCode,"size":_vm.qrcodeSize,"level":"L"}})],1),_c('div',{staticClass:"flex flex-row content-center justify-center",class:{ hidden: !_vm.showCountdown }},[_c('div',{staticClass:"text-xs text-center",class:{
              'text-gray-100': _vm.dark,
              'text-gray-700': !_vm.dark,
            }},[_vm._v(" "+_vm._s(_vm.$t('passwordlessLogin.expiresIn'))+" "),_c('span',{staticClass:"w-5 inline-block text-center"},[_vm._v(_vm._s(_vm.countdown))]),_vm._v(" "+_vm._s(_vm.$t('passwordlessLogin.seconds'))+" "),(_vm.debugmode)?_c('div',{staticClass:"opacity-30 text-center"},[_vm._v(" "+_vm._s(_vm.authId != null ? _vm.authId.substring(0, 4) : '')+" ")]):_vm._e()])])]):_c('div',[_c('div',{staticClass:"relative cursor-pointer"},[_c('qrcode-vue',{staticClass:"p-1.5 flex items-center justify-center bg-white opacity-20 rounded",attrs:{"value":'Nothing here. Just a dummy qrcode.',"size":_vm.qrcodeSize,"level":"L"}}),(_vm.disabled)?_c('div',{staticClass:"absolute w-full h-full flex items-center justify-center top-0 left-0"},[_c('div',{staticClass:"bg-white bg-opacity-80 backdrop-blur-lg font-semibold px-5 py-3 rounded-full text-sm text-black"},[_vm._v(" "+_vm._s(_vm.$t('passwordlessLogin.generateNew'))+" ")])]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"absolute w-full h-full flex items-center justify-center top-0 left-0"},[_c('fw-icon-loading',{staticClass:"w-12 h-12"})],1):_vm._e()],1),_c('div',{staticClass:"h-9"})])]),_c('b-modal',{staticClass:"h-full",attrs:{"active":_vm.isOpened,"width":640,"scroll":"keep","full-screen":"","can-cancel":false},on:{"update:active":function($event){_vm.isOpened=$event}}},[_c('div',{staticClass:"p-5 flex flex-col h-full justify-center"},[_c('fw-heading',{staticClass:"text-center",attrs:{"size":"h1"}},[_vm._v("Passwordless Login")]),_c('div',{staticClass:"my-4 flex-1 justify-center items-center flex"},[_c('b-loading',{attrs:{"active":_vm.isLoading},on:{"update:active":function($event){_vm.isLoading=$event}}}),(!_vm.isLoading && _vm.qrCode)?_c('div',{class:{
              'p-5 bg-white rounded': _vm.dark,
            }},[_c('div',{staticClass:"flex justify-center p-2"},[_c('qrcode-vue',{staticClass:"p-2 flex items-center justify-center bg-white rounded",attrs:{"value":_vm.qrCode,"size":250,"level":"L"}})],1)]):_vm._e()],1),_c('fw-button',{attrs:{"type":"black","expanded":true},nativeOn:{"click":function($event){return _vm.closeModal.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('passwordlessLogin.close')))])],1)])],1),(_vm.showInstructions)?_c('div',{staticClass:"text-center mt-2"},[_c('div',{staticClass:"text-xs text-gray-500"},[_vm._v(" "+_vm._s(_vm.$t('passwordlessLogin.scanCode'))+" "),_c('a',{staticClass:"font-bold",class:{
          'text-gray-100': _vm.dark,
          'text-gray-700': !_vm.dark,
        },attrs:{"href":"https://one.uc.pt","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('passwordlessLogin.app')))]),_vm._v(" "+_vm._s(_vm.$t('passwordlessLogin.goal'))+" ")])]):_vm._e(),(_vm.showMarketplacesBadges && _vm.links)?_c('div',{staticClass:"flex items-center justify-center gap-1 mt-1 w-full"},[_c('div',[_c('a',{attrs:{"href":`https://apps.apple.com/${_vm.appleStoreLanguage}/app/uc-one/id1611415635`}},[_c('img',{attrs:{"src":"https://one.uc.pt/images/appstore-badge.png"}})])]),_vm._m(0)]):_vm._e(),(_vm.showMarketplacesBadges && !_vm.links)?_c('div',{staticClass:"flex items-center justify-center gap-1 w-full mt-1"},[_vm._m(1),_vm._m(2)]):_vm._e()]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{attrs:{"href":"https://play.google.com/store/apps/details?id=pt.uc.one"}},[_c('img',{attrs:{"src":"https://one.uc.pt/images/google-play-badge.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"https://one.uc.pt/images/appstore-badge.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":"https://one.uc.pt/images/google-play-badge.png"}})])
}]

export { render, staticRenderFns }